<template>
  <div>
    <form @submit="sendNew">
      <div class="col-12 mb-3">
        <label for="titulo" class="form-label">Título</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.titulo"
          aria-describedby="name_help"
          placeholder="Título do Link"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="url" class="form-label">URL</label>
        <input
          type="text"
          class="form-control"
          v-model="formInclude.url"
          aria-describedby="url"
          placeholder="Link de apontamento"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="lang_input" class="form-label">{{
          $t("status")
        }}</label>
        <select
          class="form-select"
          @change="formInclude.status = $event.target.value"
          v-model="formInclude.status"
        >
          <option value="1">Ativo</option>
          <option value="0">Inativo</option>
        </select>
      </div>
      <div class="d-grid gap-2">
        <button
          class="btn btn-success bg-success bg-gradient col-6 mx-auto"
          type="submit"
        >
          {{ $t("enviar") }} {{ $t("newone") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ["actionSend"],
  data: () => ({
    formInclude: {},
  }),
  mounted() {},
  methods: {
    sendNew: function (event) {
      event.preventDefault();
      this.actionSend(this.formInclude);
    },
  },
};
</script>

<style>
</style>