import axios from 'axios';
import authHeader from './auth-header';

import { URL_API } from './Commons';

class LinksUteisService {

  async getAll() {
    return await axios.get(URL_API + 'links', { headers: authHeader() });
  }

  getOne(id) {
    return axios.get(URL_API + 'link/' + id, { headers: authHeader() });
  }

  insert(linksuteis) {
    return axios.post(URL_API + 'link', linksuteis, { headers: authHeader() });
  }

  update(id, linksuteis) {
    return axios.put(URL_API + 'link/' + id, linksuteis, { headers: authHeader() });
  }

  delete(id) {
    return axios.delete(URL_API + 'link/' + id, { headers: authHeader() });
  }

}

export default new LinksUteisService();