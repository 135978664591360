<template>
  <div>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb text-muted justify-content-end small">
        <li class="breadcrumb-item">
          {{ $t("id") }}:
          <span class="badge bg-secondary">{{ formEdit.id }}</span>
        </li>
        <li class="breadcrumb-item">
          {{ $t("dateInsert") }}:
          <span class="badge bg-secondary" v-if="moment">
            {{ moment(formEdit.created_at).format("DD/MM/YYYY") }}
          </span>
        </li>
      </ol>
    </nav>
    <form @submit="sendEdit">
      <div class="col-12 mb-3">
        <label for="titulo" class="form-label">Título</label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.titulo"
          aria-describedby="titulo"
        />
      </div>
      <div class="col-12 mb-3">
        <label for="url" class="form-label">URL </label>
        <input
          type="text"
          class="form-control"
          v-model="formEdit.url"
          aria-describedby="url"
        />
        <div id="emailHelp" class="form-text">
          <a :href="formEdit.url" target="_blank"
            >Testar Link <i class="bi bi-box-arrow-up-right"></i
          ></a>
        </div>
      </div>

      <div class="col-12 mb-3" v-if="!deleteMode">
        <label for="lang_input" class="form-label">{{ $t("status") }}</label>
        <select
          class="form-select"
          @change="formEdit.status = $event.target.value"
          v-model="formEdit.status"
        >
          <option value="1">Ativo</option>
          <option value="0">Inativo</option>
        </select>
      </div>

      <div class="d-grid gap-2">
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="!loading"
        >
          {{ $t("submit") }} <i class="bi bi-save"></i>
        </button>
        <button
          class="btn btn-dark bg-dark bg-gradient col-6 mx-auto"
          type="submit"
          v-if="loading"
          disabled
        >
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          {{ $t("loading") }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import LinksUteisService from "../../../../../services/linksuteis.service";
import LinksUteisHandle from "../../../../../services/handle/linksuteis.handle";
import moment from "moment";
export default {
  props: [
    "dataItem",
    "actionSend",
    "ActionSendFile",
    "errorServer",
    "deleteMode",
  ],
  data: () => ({
    formEdit: {},
    urlImg: "",
    urlPdf: "",
    loading: false,
    moment: null,
  }),
  mounted() {
    this.moment = moment;
    this.moment.locale("pt-br");
    this.formEdit = this.dataItem;
  },
  methods: {
    sendEdit: function (event) {
      event.preventDefault();
      this.loading = true;
      //
      if (this.deleteMode) {
        LinksUteisService.delete(this.formEdit.id).then(
          (result) => {
            console.log(result);
            this.loading = false;
            this.actionSend({
              msg: "O item foi excluído com Sucesso.",
              style: "success",
            });
          },
          (error) => {
            this.loading = false;
            this.errorServer(error);
          }
        );
        return;
      }
      LinksUteisService.update(
        this.formEdit.id,
        LinksUteisHandle.formToJso(this.formEdit)
      ).then(
        (result) => {
          result;
          this.loading = false;
          this.actionSend({
            msg: "O item foi alterado com Sucesso.",
            style: "success",
          });
        },
        (error) => {
          this.loading = false;
          this.errorServer(error);
        }
      );
    },
  },
};
</script>

<style>
</style>